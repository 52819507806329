import { NATIVE_FILTER_TYPE } from './componentTypes';
import { DashboardLayout } from '../types';

export default function getFilterIdsFromLayout(layout: DashboardLayout) {
  return Object.values(layout).reduce(
    (filterIds: string[], currentComponent) => {
      if (
        currentComponent &&
        currentComponent.type === NATIVE_FILTER_TYPE &&
        currentComponent.meta?.filterId
      ) {
        filterIds.push(currentComponent.meta.filterId);
      }
      return filterIds;
    },
    [],
  );
}
